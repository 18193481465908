<template>
  <div class="main-page">
    <Header msg="我的团队"></Header>

    <BScroll class="content" @pullingUp="fnpullingUp" ref="scrollRef">

      <van-empty description="暂无团队" v-if="data.empty"/>


      <div class="ulist">
        <div class="uitem" v-for="(item,index) in data.list" :key="index">
          <div class="title d-flex align-center justify-between">
            <div class="left d-flex align-center"  @click="copy(item.address)">
              <span>{{UU.adrTostr(item.address)}}</span>
              <img
                src="../assets/imger/cc.png"
                alt=""
                style="width: 0.22rem; margin-left: 0.1rem"
              />
            </div>
          </div>

          <div class="uinfo d-flex align-center justify-between">
            <div>
              <div class="ut"><span>等级</span></div>
              <div class="uss"><span>VIP{{ item.level }}</span></div>
            </div>

            <div>
              <div class="ut"><span>业绩(USDT)</span></div>
              <div class="uss"><span>{{item.commodity || 0}}</span></div>
            </div>

            <div class="endser">
              <div class="ut"><span>时间</span></div>
              <div class="uss"><span>{{ item.time }}</span></div>
            </div>
          </div>
        </div>
      </div>
    </BScroll>
  </div>
</template>

<script  setup>
import { reactive, toRefs, computed, watch, ref, onMounted,nextTick } from "vue";
import Header from "@/components/Header";
import BScroll from "@/components/BScroll";
import { team } from "@/common/api";
import { useStore } from "vuex";
import useClipboard from "vue-clipboard3";
import UU from '@/utils/string';
import { Toast } from 'vant';
const scrollRef = ref(null);
const { toClipboard } = useClipboard();

const $store = useStore();
const data = reactive({
  list: [],
  empty: false,
  page:0,
  pages:0
});
const account = computed(() => {
  return $store.state.account;
});
const fninit = () => {
  const params = {
    address: account.value,
    page: data.page,

  };
  team(params).then((res) => {
    if (res.code == 200) {
    //   data.pages = res.data.pages;
      data.list = [...data.list, ...res.data.users];
      if (data.list.length) {
        data.empty = false;
      } else {
        data.empty = true;
      }
      nextTick(() => {
        scrollRef.value.refresh();
      });
    } else {
      data.empty = true;
    }
  });
};

const fnpullingUp = () => {
  data.page = data.page + 1;

  if (data.page >= data.pages) {
    return;
  }
  fninit();
};

const copy = async (text) => {
  try {
    await toClipboard(text);
    Toast.success("复制成功");
  } catch (e) {
    Toast.fail(e);
  }
};

watch(
  account,
  (newVal) => {
    newVal && fninit();
  },
  { immediate: true, deep: true }
);
</script>
<style scoped lang='scss'>
.ulist {
  padding: 0 0.32rem;
  padding-bottom: 0.6rem;
  .uitem {
    height: 2rem;
    background-color: #182743;
    border-radius: 0.14rem;
    padding: 0 0.3rem;
    padding-top: 0.2rem;
    margin-top: 0.3rem;
    font-size: 0.24rem;

    .title {
      height: 0.6rem;
      font-size: 0.26rem;
    }
    .uinfo {
      height: 1.1rem;
      .ut {
        font-size: 0.22rem;
        color: #a1aec5;
      }
      .uss {
        font-size: 0.26rem;
        line-height: 0.6rem;
      }

      .endser {
        // background-color: pink;
        text-align: right;
      }
    }
  }
}
</style>